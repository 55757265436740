import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  // createWebHistory 路由模式路径不带#号(生产环境下不能直接访问项目，需要 nginx 转发)
  // createWebHashHistory 路由模式路径带#号
  history: createWebHashHistory(), 
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
			props: route => ({ mobile: route.query.m }),
      component: () => import('@/pages/login/Login'),
      meta: { title: '商城登录', keepUpdate: true }
    },
		{
		  path: '/logout',
		  name: 'logout',
		  component: () => import('@/pages/login/Logout'),
		  meta: { title: '商城退出登录', keepUpdate: true }
		},
    {
      path: '/index',
      name: 'index',
      component: () => import('@/pages/index/Index'),
      meta: { title: '兑换商城首页', keepUpdate: true }
    },
		{
			path: '/goodsInfo/:id',
			name: 'goodsInfo',
			component: () => import('@/pages/goodsInfo/GoodsInfo'),
      meta: { title: '商品兑换', keepUpdate: true }
		},
		{
			path: '/payresult',
			name: 'payresult',
			component: () => import('@/pages/payment/PayResult'),
      meta: { title: '兑换结果', keepUpdate: true }
		},
		{
			path: '/payments',
			name: 'payments',
			component: () => import('@/pages/payment/Payments'),
      meta: { title: '兑换记录', keepUpdate: true }
		}
  ]
})

// 解决 vue 中路由跳转时，总是从新页面中间开始显示
router.afterEach((to,from,next) => {
  window.scrollTo(0,0)
})

export default router